<script lang="ts">
  import type { CountryRoutesQuery } from '$lib/queries/generated/QueryCountryRoutes'
  import { ArrowRight } from '@steeze-ui/heroicons'
  import { Icon } from '@steeze-ui/svelte-icon'
  import LinkButton from '../ui/buttons/LinkButton.svelte'
  import PopularDestination from './PopularDestination.svelte'

  export let destinations: CountryRoutesQuery['routeSummariesForRoutes']
</script>

<div class="px-4 pt-0 pb-0 sm:pt-16 sm:pb-24 mx-auto md:px-8 lg:max-w-screen-xl sm:mx-6 lg:mx-auto">
  <!-- <span class="py-1 font-medium tracking-wide uppercase"> In the meantime</span> -->
  <h2
    class="text-2xl font-extrabold leading-relaxed tracking-tight text-black sm:text-3xl sm:leading-normal lg:mb-4"
  >
    View Travel Restrictions
  </h2>
  <p class="max-w-2xl mb-6 leading-normal">
    Find travel restrictions for your favorite destinations, discover countries that are open for
    travel, and subscribe to receive updates of changing travel requirements for 242 countries.
  </p>
  <ol class="grid grid-cols-1 gap-3 list-none sm:grid-cols-2 lg:grid-cols-3">
    {#if destinations != null}
      {#each destinations as destination (destination.id)}
        <PopularDestination routeSummary={destination} />
      {/each}
    {/if}
  </ol>
  <div class="flex flex-col items-center justify-start gap-4 mt-6 sm:flex-row">
    <LinkButton
      theme="dark"
      priority="secondary"
      size="xl"
      href={'/travel-restrictions/united-states-vaccinated'}
      class="w-full sm:w-auto mx-0"
    >
      Explore Restrictions
    </LinkButton>
    <a
      href="/countries/popular"
      id="all-popular"
      class="inline-flex items-center space-x-2 font-medium leading-6 text-black group ring-transparent focus:outline-none"
    >
      <span>View All Popular Routes</span>
      <div
        class="flex items-center justify-center w-4 h-4 transition duration-100 ease-in-out transform translate-x-0 rounded-full group-hover:translate-x-2"
      >
        <Icon src={ArrowRight} class="w-4 h-4" />
      </div>
    </a>
  </div>
</div>
