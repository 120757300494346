<script lang="ts">
  import { browser } from '$app/environment'

  import { getWaitlistPos, WaitlistCount } from '$lib/authentication/helpers'
  import {
    CurrentUserDocument,
    type CurrentUserQuery,
    type CurrentUserQueryVariables,
  } from '$lib/queries/generated/currentUser'
  import state from '$lib/stores/auth-state'
  import modal from '$lib/stores/modal-state'
  import { getContextClient, queryStore } from '@urql/svelte'
  import cdnImageURL, { cdnSrcSetHiDPI } from 'src/utils/cdn'
  import { track } from 'src/utils/track'
  import Button from '../ui/Button.svelte'
  import LinkButton from '../ui/buttons/LinkButton.svelte'

  function showWaitlist() {
    modal.open('signup', { intent: 'joinWaitlist' })
  }

  const handleWaitlistClick = () => {
    track('Clicked - Join Waitlist - Landing Page - Hero')
    showWaitlist()
  }

  $: isLoggedIn = $state.signedIn
  const client = getContextClient()
  $: userStore = queryStore<CurrentUserQuery, CurrentUserQueryVariables>({
    client,
    query: CurrentUserDocument,
    variables: {},
    pause: !$state.signedIn,
  })

  $: user = $userStore.data?.currentUser
</script>

<section
  class="overflow-hidden lg:max-w-screen-xl pb-24 mx-auto lg:grid lg:grid-cols-2 lg:gap-4"
  data-sveltekit-preload-data
>
  <div class="px-4 py-24 mx-auto md:px-8 lg:max-w-screen-xl sm:mx-6 lg:mx-0">
    <span class="py-1 text-base font-medium tracking-wide uppercase">Coming Soon</span>
    <h2
      class="text-2xl font-extrabold leading-relaxed tracking-tight text-black sm:text-3xl sm:leading-normal lg:mb-4"
    >
      <span class="block">Create and Share Travel Plans</span>
    </h2>

    <div class="space-y-6">
      {#if user == null || !isLoggedIn || !browser}
        <p class="max-w-2xl leading-normal">
          Gain inspiration from knowledgabel travelers and design the trips of your dreams all in
          one beautiful, integrated space.
        </p>

        <p class="max-w-2xl leading-normal">
          Join over {WaitlistCount.toLocaleString()} travelers on the waitlist.
        </p>

        <Button id="waitlist-cta-bottom" class="text-xl px-8 py-6" on:click={handleWaitlistClick}
          >Gain Early Access</Button
        >
      {:else if user?.waitListedAt != null}
        <p class="max-w-2xl leading-normal">
          Nice! You&apos;re currently #{(getWaitlistPos(user) ?? 1).toLocaleString()} on the waitlist.
          We&apos;ll send you an email as soon as it&apos;s ready.
        </p>
        <div class="flex flex-col items-start justify-center mt-8">
          <span class="text-base font-medium tracking-wide uppercase mb-2"> In the meantime </span>
          <LinkButton
            href="/travel-restrictions/united-states-vaccinated"
            theme="dark"
            size="xl"
            priority="secondary"
            class="w-full sm:w-auto mx-0"
          >
            Explore Where to Travel
          </LinkButton>
        </div>
      {:else}
        <p class="max-w-2xl leading-normal">
          View more specific restrictions and policies for each leg of your trip. Access your trip
          details all in one place.
        </p>
        <!-- <Button id="plan-trip-cta-bottom" class="text-xl px-8 py-6" on:click={handlePlanTripClick}
          >Plan Your Trip</Button
        > -->
      {/if}
    </div>
  </div>

  <div class="relative hidden max-w-5xl mx-auto mt-12 md:block">
    <img
      width="1024"
      height="615"
      class="w-full"
      src={cdnImageURL({ src: '/marketing/marketing/landing/hero.png', width: 1024, height: 615 })}
      srcset={cdnSrcSetHiDPI('/marketing/marketing/landing/hero.png', 1024, 615)}
      alt="Airheart App screenshot"
    />
  </div>
  <div class="block md:hidden aspect-w-6 aspect-h-4 -mt-12 md:aspect-w-2 md:aspect-h-1">
    <img
      class="object-cover object-left-top transform translate-x-0 translate-y-6 md:translate-x-0 lg:translate-y-12"
      src={cdnImageURL({ src: '/marketing/marketing/landing/hero.png', width: 1024, height: 615 })}
      srcset={cdnSrcSetHiDPI('/marketing/marketing/landing/hero.png', 1024, 615)}
      loading="lazy"
      width="1024"
      height="615"
      alt="Airheart App screenshot"
    />
  </div>
</section>
