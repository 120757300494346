<section
  class="w-full mt-6 overflow-hidden bg-white mb-40 space-y-6 md:mb-8 lg:mb-60 md:mt-12 flex flex-col items-center justify-center max-w-md px-6 mx-auto text-lg font-medium leading-relaxed tracking-tight text-black md:max-w-xl lg:px-8 lg:max-w-2xl lg:text-xl"
>
  <p>
    Whether you&apos;re looking for travel inspiration or you have your ideal trip in mind already,
    you&apos;ve come to the right place.
  </p>
  <p>
    Airheart is a dedicated space for crafting your perfect trip and sharing it with your community.
  </p>

  <p>
    We empower explorers, like yourself, to build brilliant itineraries and travel smarter, the way
    you envision it.
  </p>
</section>
