<script lang="ts">
  import {
    CurrentUserDocument,
    type CurrentUserQuery,
    type CurrentUserQueryVariables,
  } from '$lib/queries/generated/currentUser'
  import state from '$lib/stores/auth-state'

  import { getContextClient, queryStore } from '@urql/svelte'
  import cdnImageURL, { cdnSrcSetHiDPI } from 'src/utils/cdn'
  import Waitlist from './Waitlist.svelte'

  const client = getContextClient()
  $: userStore = queryStore<CurrentUserQuery, CurrentUserQueryVariables>({
    client,
    query: CurrentUserDocument,
    variables: {},
    pause: !$state.signedIn,
  })

  $: user = $userStore.data?.currentUser
</script>

<main class="relative pt-6 max-w-screen-xl mx-auto mt-8 sm:mt-12 md:mt-20 xl:mt-24">
  <div class="flex flex-col items-center justify-center text-center px-4 sm:px-6">
    {#if !user || user?.waitListedAt !== null}
      <span class="py-1 text-base font-medium tracking-wide uppercase">Coming Soon</span>
    {/if}
    <h1
      class="mt-1 text-4xl font-extrabold leading-10 tracking-tight text-black sm:leading-none sm:text-5xl md:text-6xl"
    >
      Create and Share <br class="inline" />
      <span class="text-brand-primary">Travel Plans</span>
    </h1>
    <div class="flex flex-col items-center mt-5 sm:max-w-lg sm:mx-auto sm:text-center">
      <Waitlist />
    </div>
  </div>
  <div class="relative hidden max-w-5xl mx-auto mt-12 md:block">
    <img
      width="1024"
      height="615"
      class="w-full"
      src={cdnImageURL({ src: '/marketing/marketing/landing/hero.png', width: 1024, height: 615 })}
      srcset={cdnSrcSetHiDPI('/marketing/marketing/landing/hero.png', 1024, 615)}
      alt="Airheart App screenshot"
    />
  </div>
  <div class="block mt-6 md:hidden aspect-w-6 aspect-h-4 md:aspect-w-2 md:aspect-h-1">
    <img
      class="object-cover object-left-top transform translate-x-0 translate-y-6 md:translate-x-0 lg:translate-y-12"
      src={cdnImageURL({ src: '/marketing/marketing/landing/hero.png', width: 1024, height: 615 })}
      srcset={cdnSrcSetHiDPI('/marketing/marketing/landing/hero.png', 1024, 615)}
      loading="lazy"
      width="1024"
      height="615"
      alt="Airheart App screenshot"
    />
  </div>
</main>
