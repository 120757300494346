<script>
  import cdnImageURL from 'src/utils/cdn'
</script>

<section class="relative w-full lg:text-left h-[700px] flex justify-center items-center mx-auto">
  <img
    height="700"
    class="object-cover object-center h-[700px] w-full inset-0"
    src={cdnImageURL({ src: '/marketing/marketing/landing/pool-full-white.png', height: 700 })}
    alt="woman with coconut in pool"
    loading="lazy"
  />
  <div
    class="absolute w-full flex items-center justify-center px-4 mx-auto sm:px-6 lg:px-4 lg:grid lg:grid-cols-2"
  >
    <div class="flex items-center justify-center lg:grid-span-1">
      <div class="relative max-w-md text-black">
        <h2 class="mb-12 text-2xl font-extrabold leading-8 tracking-tight sm:text-3xl sm:leading-9">
          Organize Group Travel Effectively Like Never Before
        </h2>
        <div class="text-lg font-semibold leading-7 py-auto">
          <p>
            It&apos;s difficult to keep your group on the same page when organizing travel. It can
            be painful to the point where you might just welcome the chaos. Anything to avoid
            chasing people down for their hotel preferences or flight landing times.
          </p>
          <p class="mt-4">
            With Airheart, we take care of this for you, so you can focus on enjoying the trip with
            your group members.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="lg:relative lg:flex">
  <div class="relative w-full lg:text-left lg:w-1/2 h-[700px] flex justify-center">
    <img
      class="inset-0 object-cover object-center w-full h-full"
      height={700}
      src={cdnImageURL({ src: '/marketing/marketing/landing/camping-dark.png', height: 700 })}
      alt="camping under the night sky"
      loading="lazy"
    />

    <div
      class="absolute my-auto h-full flex flex-col items-left justify-center mx-auto px-6 lg:px-8"
    >
      <h2
        class="max-w-sm text-white mb-12 text-2xl font-extrabold leading-8 tracking-tight sm:text-3xl sm:leading-snug"
      >
        Plan Brilliant Itineraries Every Time
      </h2>
      <div class="h-64 text-lg font-semibold max-w-md text-white leading-relaxed py-auto">
        <p>
          Make the most of your trips by utilizing a dedicated space for organizing all of your
          travel plans, even if they're only aspirational. Unlock your creativity with a bit of
          inspiration from peers. Dream big and plan the epic itineraries your heart desires.
        </p>
      </div>
    </div>
  </div>
  <div class="relative w-full lg:text-left lg:w-1/2 h-[700px] flex justify-center">
    <img
      class="inset-0 object-cover object-center w-full h-full"
      src={cdnImageURL({ src: '/marketing/marketing/landing/tokyo-dark.png', height: 700 })}
      height={700}
      alt="japanese city at night"
      loading="lazy"
    />
    <div
      class="absolute my-auto h-full flex flex-col items-left justify-center mx-auto px-6 lg:px-8"
    >
      <h2
        class="mb-12 max-w-sm text-white text-2xl font-extrabold leading-8 tracking-tight sm:text-3xl sm:leading-9"
      >
        Be Prepared for Whatever Comes Your Way
      </h2>
      <div class="h-64 text-lg max-w-md text-white font-semibold leading-7 py-auto">
        <p>
          Equip yourself with a unified itinerary for the entire trip, including supplementary data
          on flights, lounges, traffic reports, cancellations, and more. Stay on top of your plans
          with step-by-step reminders and real-time updates if plans change on you unexpectedly.
        </p>
      </div>
    </div>
  </div>
</section>
