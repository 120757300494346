<script lang="ts">
  import { browser } from '$app/environment'
  import { getWaitlistPos, WaitlistCount } from '$lib/authentication/helpers'
  import {
    CurrentUserDocument,
    type CurrentUserQuery,
    type CurrentUserQueryVariables,
  } from '$lib/queries/generated/currentUser'
  import state from '$lib/stores/auth-state'
  import modal from '$lib/stores/modal-state'
  import { getContextClient, queryStore } from '@urql/svelte'
  import { track } from 'src/utils/track'
  import Button from '../ui/Button.svelte'
  import LinkButton from '../ui/buttons/LinkButton.svelte'

  function showWaitlist() {
    modal.open('signup', { intent: 'joinWaitlist' })
  }

  const handleWaitlistClick = () => {
    track('Clicked - Join Waitlist - Landing Page - Hero')
    showWaitlist()
  }

  const handlePlanTripClick = (e: any) => {
    track('Clicked - Plan A Trip - Landing Page - Hero')
    window.location.href = '/trips'
  }

  $: isLoggedIn = $state.signedIn
  const client = getContextClient()
  $: userStore = queryStore<CurrentUserQuery, CurrentUserQueryVariables>({
    client,
    query: CurrentUserDocument,
    variables: {},
    pause: !$state.signedIn,
  })

  $: user = $userStore.data?.currentUser
</script>

{#if !isLoggedIn || !browser}
  <div class="flex flex-col items-center pt-4">
    <Button
      aria-label="Join the waitlist"
      on:click={handleWaitlistClick}
      class="px-8 py-6 text-xl font-semibold rounded-full"
    >
      Gain Early Access
    </Button>

    <p class="mt-4 text-base font-medium leading-5 text-black">
      Join Over {WaitlistCount.toLocaleString()} Travelers on the Waitlist
    </p>
  </div>
{:else if user?.waitListedAt != null}
  <div class="pt-4">
    <h2 class="text-xl font-medium tracking-tight">
      <p class="max-w-2xl leading-normal">
        Nice! You&apos;re currently #{(getWaitlistPos(user) ?? 1).toLocaleString()} on the waitlist.
        We&apos;ll send you an email as soon as it&apos;s ready.
      </p>
      <div class="flex flex-col items-center justify-center mt-8">
        <span class="text-base font-medium tracking-wide uppercase mb-2"> In the meantime </span>
        <LinkButton
          href="/travel-restrictions/united-states-vaccinated"
          theme="dark"
          size="xl"
          priority="secondary"
          class="w-full sm:w-auto mx-0"
        >
          Explore Where to Travel
        </LinkButton>
      </div>
    </h2>
  </div>
  <!-- {:else}
  <div class="pt-4">
    <h2 class="flex flex-wrap text-xl font-medium tracking-tight">
      <Button
        on:click={handlePlanTripClick}
        class="px-8 py-6 mt-2 text-xl font-semibold rounded-full"
      >
        Plan A Trip
      </Button>
    </h2>
  </div> -->
{/if}
