<script lang="ts">
  import cdnImageURL from 'src/utils/cdn'

  export let link: string = null
  export let src: string = null
  export let alt: string = null
</script>

<div class="flex items-center justify-center col-span-1 p-4 bg-white sm:p-6 lg:p-4">
  <a href={link} rel="noreferrer" target="_blank">
    <img
      src={cdnImageURL({ src, width: 160, height: 48 })}
      {alt}
      loading="lazy"
      width={160}
      height={48}
    />
  </a>
</div>
