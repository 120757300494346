<script lang="ts">
  import Hero from '$lib/components/landing/Hero.svelte'
  import Mission from '$lib/components/landing/Mission.svelte'
  import PopularDestinations from '$lib/components/landing/PopularDestinations.svelte'
  import PressLogos from '$lib/components/landing/PressLogos.svelte'
  import SuperPowers from '$lib/components/landing/SuperPowers.svelte'
  import WaitlistCTA from '$lib/components/landing/WaitlistCTA.svelte'
  // import TravelerTips from '$lib/components/landing/TravelerTips.svelte'
  import HotelPreview from '$lib/components/landing/HotelPreview.svelte'
  import SvelteSeo from 'svelte-seo'
  import type { PageServerData } from './$types'
  import PopularVisas from '$lib/components/landing/PopularVisas.svelte'

  export let data: PageServerData

  // $: tipsData = data.tipsData
  $: title = data.title
  $: description = data.description
  $: routeSummariesForRoutes = data.routeSummariesForRoutes
  $: hotels = data.hotels

  const images = [
    {
      url: `https://airheart.com/marketing/marketing/airheart-social.png`,
      width: 1200,
      height: 600,
      alt: title,
    },
  ]
</script>

<SvelteSeo
  twitter={{
    description,
    title,
    image: `https://airheart.com/marketing/marketing/airheart-social.png`,
    imageAlt: title,
    site: '@airheartinc',
    card: 'summary_large_image',
  }}
  openGraph={{
    title,
    images,
    description,
  }}
/>

<div class="col-span-12">
  <Hero />
  <PressLogos />
  <Mission />
  <SuperPowers />
  <!-- <HotelPreview {hotels} /> -->

  <PopularDestinations destinations={routeSummariesForRoutes} />
  <PopularVisas />
  <WaitlistCTA />
</div>
