<script lang="ts">
  import { ArrowRight } from '@steeze-ui/heroicons'
  import { Icon } from '@steeze-ui/svelte-icon'
  import { encodeVisasOriginUrl } from 'src/utils/restrictions/restriction-urls'
  import LinkButton from '../ui/buttons/LinkButton.svelte'

  const countries = [
    {
      name: 'United States',
      slug: 'united-states',
    },
    {
      name: 'Turkey',
      slug: 'turkey',
    },
    {
      name: 'Kenya',
      slug: 'kenya',
    },
    {
      name: 'Canada',
      slug: 'canada',
    },
    {
      name: 'Vietnam',
      slug: 'vietnam',
    },
    {
      name: 'New Zealand',
      slug: 'new-zealand',
    },
    {
      name: 'Sri Lanka',
      slug: 'sri-lanka',
    },
    {
      name: 'Cambodia',
      slug: 'cambodia',
    },
  ]
</script>

<div class="px-4 pt-16 sm:pb-24 mx-auto md:px-8 lg:max-w-screen-xl sm:mx-6 lg:mx-auto">
  <!-- <span class="py-1 font-medium tracking-wide uppercase"> In the meantime</span> -->
  <h2
    class="text-2xl font-extrabold leading-relaxed tracking-tight text-black sm:text-3xl sm:leading-normal lg:mb-4"
  >
    View Visa Requirements
  </h2>
  <p class="max-w-2xl mb-6 leading-normal">
    Learn about visa requirements for 242 countries and regions. Purchase eVisas for your next trip.
  </p>
  <ol class="grid grid-cols-2 gap-3 list-none sm:grid-cols-3 lg:grid-cols-4">
    {#each countries as country}
      <li>
        <a
          href={encodeVisasOriginUrl(country.slug)}
          class="no-underline rounded outline-none hover:underline focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-grey-dk"
        >
          {country.name}
        </a>
      </li>
    {/each}
  </ol>
  <div class="flex flex-col items-center justify-start gap-4 mt-6 sm:flex-row">
    <LinkButton
      theme="dark"
      priority="secondary"
      size="xl"
      href={'/visas/evisas'}
      class="w-full sm:w-auto mx-0"
    >
      Explore eVisas
    </LinkButton>
    <a
      href="/visas"
      id="all-popular"
      class="inline-flex items-center space-x-2 font-medium leading-6 text-black group ring-transparent focus:outline-none"
    >
      <span>View All Countries</span>
      <div
        class="flex items-center justify-center w-4 h-4 transition duration-100 ease-in-out transform translate-x-0 rounded-full group-hover:translate-x-2"
      >
        <Icon src={ArrowRight} class="w-4 h-4" />
      </div>
    </a>
  </div>
</div>
