<script lang="ts">
  import Logo from './Logo.svelte'
</script>

<div class="bg-white">
  <div class="max-w-5xl py-12 mx-auto lg:py-16">
    <p class="text-center">
      <span class="py-1 text-base font-medium tracking-wide uppercase">As Seen On</span>
    </p>

    <div class="mt-4 grid grid-cols-2 gap-0.5 md:grid-cols-5">
      <Logo
        link="https://ktrh.iheart.com/content/2021-05-20-international-travel-is-still-complicated/"
        src={'/marketing/marketing/press-logos/iheart-radio.png'}
        alt="iHeart Radio"
      />

      <Logo
        link="https://www.forbes.com/sites/christopherelliott/2021/05/22/do-i-need-a-covid-test-to-travel-and-other-summer-travel-questions/?sh=5fd6da071c31"
        src={'/marketing/marketing/press-logos/forbes.png'}
        alt="Forbes"
      />
      <Logo
        link="https://www.washingtonpost.com/travel/tips/europe-travel-us-summer-travel/"
        src={'/marketing/marketing/press-logos/washington-post.png'}
        alt="Washington Post"
      />
      <Logo
        link="https://www.johnnyjet.com/airheart-helps-travelers-navigate-travel-entry-requirements"
        src={'/marketing/marketing/press-logos/johnny-jet.png'}
        alt="Johnny Jet"
      />
      <Logo
        link="https://www.builtinaustin.com/2021/10/19/austin-future-5-startups-q4-2021"
        src={'/marketing/marketing/press-logos/builtin.png'}
        alt="BuiltIn"
      />

      <Logo
        link="https://www.travelpulse.com/gallery/destinations/independence-day-2021-10-safest-states-to-visit-this-4th-of-july.html"
        src={'/marketing/marketing/press-logos/travel-pulse.png'}
        alt="Travel Pulse"
      />
      <Logo
        link="https://twitter.com/nomadicmatt/status/1402612009057783811?s=20"
        src={'/marketing/marketing/press-logos/nomadic-matt.png'}
        alt="Nomadic Matt"
      />
      <Logo
        link="https://skift.com/2021/10/08/kyte-raises-30-million-for-rental-cars-travel-startup-funding-this-week/"
        src={'/marketing/marketing/press-logos/skift.png'}
        alt="Skift"
      />
      <Logo
        link="https://www.businesstravelerusa.com/business-traveler-usa-story/new-app-helps-travelers-navigate-complex-border-restrictions-"
        src={'/marketing/marketing/press-logos/business-traveler.png'}
        alt="Business Traveler"
      />

      <Logo
        link="https://www.cbsnews.com/radio/"
        src={'/marketing/marketing/press-logos/cbs-news.png'}
        alt="CBS News Radio"
      />
    </div>
  </div>
</div>
